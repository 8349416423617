import React from 'react'
import Home from './components/home'
import { BrowserRouter ,  Route, Routes } from 'react-router-dom'
import About from './components/about';
import Contact from './components/contact';
import Services from './components/services';
import Header from './components/header';
import Careers from './components/careers';



const Routing = () => {
  return (
    <div>

<BrowserRouter>
    <Header/>
  
      <Routes>
        <Route  path='/' element={<Home/>}/>
        <Route  path='/about' element={<About/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/careers' element={<Careers/>}/>
       
      </Routes>
      
    </BrowserRouter>

    </div>
  )
}

export default Routing