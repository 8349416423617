import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const Header = () => {

	const [click, setClick] = useState(false);

	const handleClick = () => setClick(!click);
	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-dark sticky-top nav_wrapper navbar_sticky_sec px-xl-0 px-lg-0 px-md-3 px-sm-3 px-3">
				<div className="container header_nav_wrapper"  >
					<img src="assets/images/logo_techglobal.png" className="img-fluid logo_img" />
					<h1 className='navbar-image-header'><span style={{color:'red'}}>Tech </span><span style={{color:'orange'}}>Global</span> <span style={{color:"skyblue"}}>India <br /></span> <p>Making Things Possible</p></h1>					
					<ul className={click ? "nav-menu active" : "nav-menu"}>
						<li className="nav-item">
							<NavLink
								exact
								to="/"
								activeClassName="active"
								className="nav-links"
								onClick={handleClick}
							>
								HOME
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink
								exact
								to="/about"
								activeClassName="active"
								className="nav-links"
								onClick={handleClick}
							>
								ABOUT
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink
								exact
								to="/services"
								activeClassName="active"
								className="nav-links"
								onClick={handleClick}
							>
								SERVICES
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink
								exact
								to="/careers"
								activeClassName="active"
								className="nav-links"
								onClick={handleClick}
							>
								CAREERS
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink
								exact
								to="/contact"
								activeClassName="active"
								className="nav-links"
								onClick={handleClick}
							>
								CONTACT
							</NavLink>
						</li>
					</ul>
					<div onClick={handleClick}>
						<button class="navbar-toggler navbar-toggler-right align-self-centerCCC ml-auto collapsed" type="button" data-toggle="collapse" data-target="#main_nav">
							<span> </span>
							<span> </span>
							<span> </span>
						</button>
					</div>
				</div>
			</nav>
		</>
	);
}

export default Header