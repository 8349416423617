import React from 'react'
import Header from './header'
import Footer from './footer'

const Careers = () => {
    return (
        <div>
            <header>
                <Header />
            </header>


            <section class="banner_mainBox">
				<figure className="position-relative">
					<img className="img-fluid" src="assets/images/banner_03.jpg" alt="image" />
					<figcaption style={{color:'white', fontSize:'2.6vw'}}>We are here to cater your<br/> needs as well as your dreams.				
					</figcaption>
				</figure>
			</section>

            <section className="career_pannel_mainBox">
                <div className="container">
                    <div className="career_pannel_wrapper">

                        <div className="career_pannel_box">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="contact_title_1 mb-3">CAREERS</div>
                                    <h5 className="halfLine_title_1">We're Hiring!</h5>
                                    <div className="row career_dataBox">
                                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                                            <div className="career_text">
                                                We are continuously hiring smart and talented people. We look for creative individuals who have a passion for learning, understanding different technologies, who are determined to think out of box. If you are passionate about technology and innovation or media and creativity we are looking for you.
                                            </div>
                                        </div>
                                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 mb-4">
                                            <div className="career_img">
                                                <img src="assets/images/career_text_img.jpg" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="halfLine_title_1">Current Openings</h5>
                                    <div className="jobDetail_divBox">
                                        <div className="table-responsive">
                                            <table className="table table_sec_1" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <th width="5%">S.No</th>
                                                    <th width="80%">Openings</th>
                                                    <th width="15%">Exp</th>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Java with Springboot</td>
                                                    <td>5+yrs</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>ReactJS/ReactNative Developer</td>
                                                    <td>3+yrs</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>NodeJS Developer</td>
                                                    <td>5+yrs</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>4</td>
                                                    <td>Salesforce Developer</td>
                                                    <td>3+yrs</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cv_apply_dataBox">
                                        <p>To apply email your CV and a short write-up about your passion and</p>
                                        <p>achievements to <span><i className="fa fa-envelope"></i> <a href="mailto:hr@techglobalindia.com">hr@techglobalindia.com</a></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="footer_pannel_mainBox">
                <Footer />
            </section>






        </div>
    )
}

export default Careers