import React from 'react'

const Footer = () =>{
  return (
	<section className="footer_pannel_mainBox">
	<footer className="container">
		<div className="footer_pannel_wrapper">
			<div className="row">
				<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
					<a href="javascript:;"><img src="assets/images/logo_techglobal.png" className="img-fluid logoSize_footer"/></a>
					<div className="clearfix"></div>
					<p className="footer_para_text">TGI (Tech Global India) is a team of expert developers on Cloud & Infrastructure, Web App Development, Salesforce Services, Innovative Technology Services, eCommerce and Product Engineering, who offer services from startup companies to Enterprises.</p>
					<div className="clearfix"></div>
					<ul className="list-group list-group-flush list-group-horizontal social_network social_circle">
						<li className="list-group-item"><a href="javascript:;" className="icoFacebook" title="Facebook"><i className="fa fa-facebook"></i></a></li>
						<li className="list-group-item"><a href="javascript:;" className="icoLinkedin" title="Linkedin"><i class="fa fa-linkedin"></i></a></li>
						<li className="list-group-item"><a href="javascript:;" className="icoInstagram" title="Instagram"><i className="fa fa-instagram"></i></a></li>
						{/* <li className="list-group-item"><a href="javascript:;" className="icoTwitter" title="Twitter"><i className="fa fa-twitter"></i></a></li> */}
						{/* <li className="list-group-item"><a href="javascript:;" className="youTube" title="Rss"><i className="fa fa-youtube-play"></i></a></li> */}
						 
						{/* <li><a href="javascript:;" class="icoGoogle" title="Google +"><i class="fa fa-google-plus"></i></a></li> */}
					</ul>				
				</div>

				<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3 mt-xl-5 mt-lg-5 mt-0">
					<h5 className="listMenu_title">Services</h5>
					<ul className="list-group list-group-flush customListMenu_footer">
						<li className="list-group-item"><a href="/services">Web Application Development</a></li>
						<li className="list-group-item"><a href="/services">Mobile Development</a></li>
						<li className="list-group-item"><a href="/services">Salesforce Services</a></li>
						<li className="list-group-item"><a href="/services">Digital Solutions</a></li>
						<li className="list-group-item"><a href="/sevices">Cloud Managed Services</a></li>
						<li className="list-group-item"><a href="/services">Infrastructure Monitoring</a></li>
						<li className="list-group-item"><a href="/services">Web Design</a></li>
						<li className="list-group-item"><a href="/services">Software Engineering</a></li>
					</ul>
				</div>

				<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3 mt-xl-5 mt-lg-5 mt-0">
					<h5 className="listMenu_title">Company</h5>
					<ul className="list-group list-group-flush customListMenu_footer">
						<li className="list-group-item"><a href="/about">About</a></li>
						<li className="list-group-item"><a href="/services">Services</a></li>
						<li className="list-group-item"><a href="/careers">Career</a></li>
						<li className="list-group-item"><a href="/contact">Contact</a></li>
					</ul>
				</div>

				<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3 mt-xl-5 mt-lg-5 mt-0">
					<h5 className="listMenu_title">Contact</h5>
					<div className="contact_address footer_contAddress">
						<p>
							#62, 2nd Floor, HIG Colony,<br/>
							Annanagar 80 Feet Road,<br/>
							Madurai-625020, Tamil Nadu, India
						</p>
						<ul className="list-group list-group-flush customList_2_contact footer_contAddress">
							<li className="list-group-item">
								<span><a href="tel: 0452 - 7963403">0452-7963403</a></span>
							</li>
							<li className="list-group-item">
								<span><a href="mailto:hr@techglobalindia.com">hr@techglobalindia.com</a></span>
							</li>
							<li className="list-group-item">
								<span>10:00AM - 07:00PM</span>
							</li>
						</ul>
					</div>
				</div>
				
			</div>
		</div>
	</footer>	
	
	
	<footer className="footerCopyRight_sec">
		<div className="container">
			<div className="row">
				<div className="col-md-12 copyrightText">
				{/* © Copyright 2022, Tech Global India, All Rights Reserved. */}
				      {/* © 2016-2023 Tech Global India.com */}
					  © Copyright 2016-2023, Tech Global India, All Rights Reserved.
				</div>
			</div>
		</div>	
	</footer>
	<a id="back-to-top" href="#" className="btn back-to-top" role="button">
	<span className="fa fa-arrow-up"></span>
</a>
</section>


  )
}

export default Footer