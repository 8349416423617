import React,{useRef} from 'react'
import Header from './header'
import Footer from './footer'
import emailjs from '@emailjs/browser';
function About() {

	const form = useRef();
    
    
    const sendEmail = (e) => {
        console.log("reach send email",e)
        e.preventDefault();
        emailjs.sendForm("service_6e4pg09","template_nbsw0yj", form.current, 'vpNHCXG0q-u8e5EwB') // hr
          .then((result) => {
              console.log("result",result.text); 
          }, (error) => {
              console.log("error",error.text);
          });  
        
      }
	return (
		<div>
			<header>
				<Header/>		
			</header>

			<section className="service_banner_pannel_mainBox">
                <div className="service_banner_pannel_box">
                <figure className="position-relative">
					<img className="img-fluid" src="assets/images/banner_03.jpg" alt="image"/>
             <figcaption> <h3>
			  We use Agile business processes and the latest technology <br/> to make your business flexible.
				</h3> </figcaption>
          
				</figure>
            </div>
            </section>


			<section className="aboutus_secOne_pannel_mainBox">
	<div className="container">
		<div className="aboutus_secOne_pannel_wrapper">

			<div className="row">
				<div className="col-md-6 mb-3">
					<div className="aboutus_secOne_left">
						<div className="contact_title_1 mb-3">ABOUT US</div>
						<h2>Our Foremost Aim Is To Achieve Success In Every Business With Our Technology</h2>
						<p>We started our journey in 2016. This an offshore based IT services company from Madurai, India. We have huge experience in software development and other IT services.</p>
						<p>We keep on upgrading our skill set by building and transferring digital skills and expertise. To bring transformation to the enterprise, we help them with a next- generation technological solution.</p>
						<p>As a leading IT company in Madurai, we provide our clients with cutting-edge solutions for business growth. By using agile business processes and the latest technology, we leverage our effort to make your business flexible. Our service will help your business in adapting all the changes taking place in the market. Moreover, we provide services to different industries like banking, insurance, healthcare, financial and others.</p>
					</div>	
				</div>
				<div className="col-md-6 mb-4">
					<div className="aboutus_secOne_right">
						<img className="img-fluid" src="assets/images/aboutus_page_img_1.jpg"/>
					</div>	
				</div>
			</div>	

			<div className="our_mission_vission_pannel">
				<div className="row">
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
						<div className="vission_left">
							<img className="img-fluid" src="assets/images/aboutus_page_img_2.jpg"/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
						<div className="vission_right">
							<div className="row">
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="event_card_box">
										<img src="assets/images/aboutus_our_mission.jpg" className="img-fluid card_img"/>
										<div className="abtus_mission_detail">
											<p className="text_2">Our Mission</p>
											<p className="text_3">Our main aim is to provide a solution that helps the enterprise in staying ahead in this changing world.</p>
										</div>
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="event_card_box">
										<img src="assets/images/aboutus_our_vission.jpg" className="img-fluid card_img"/>
										<div className="abtus_mission_detail">
											<p className="text_2">Our Vision</p>
											<p className="text_3">To bring transformation to the enterprise, we help them with a next- generation technological solution.</p>
										</div>
									</div>
								</div>
							</div>
						</div>	
					</div>
				</div>	
			</div>				

		</div>
	</div>
</section>






<section className="smart_approach_pannel_mainBox">
	<div className="container">
		<div className="smart_approach_pannel_wrapper">	

			<div className="smart_approach_pannel_box">
				<h5 className="halfLine_title_1">Our Smart Approach</h5>
				<div className="row">
					<div className="col-md-3 mb-3">
						<div className="smart_box">
							<img className="img-fluid" src="assets/images/smart_image_discovery.png"/>
							<p>1 Discovery</p>
						</div>
					</div>
					<div className="col-md-3 mb-3">
						<div className="smart_box">
							<img className="img-fluid" src="assets/images/smart_image_planning.png"/>
							<p>2 Planning</p>
						</div>
					</div>
					<div className="col-md-3 mb-3">
						<div className="smart_box">
							<img className="img-fluid" src="assets/images/smart_image_execute.png"/>
							<p>3 Execute</p>
						</div>
					</div>
					<div className="col-md-3 mb-3">
						<div className="smart_box">
							<img className="img-fluid" src="assets/images/smart_image_deliver.png"/>
							<p>4 Deliver</p>
						</div>
					</div>
				</div>	
			</div>		

		</div>
	</div>
</section>







<section className="callBack_pannel_mainBox">
	<div className="container">
		<div className="callBack_pannel_wrapper">	

			<div className="callBack_pannel_box">
				<div className="row">
					<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-3">	
						<h5 className="halfLine_title_2">Request For Call Back</h5>
						<form id="form" ref={form} onSubmit={sendEmail}>
                                    <div class="callBack_left">

                                        <div class="callBack_form_sec">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control placeHolder_text custom_input"  name="user_name" id="user_name"placeholder="Name" />
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control placeHolder_text custom_input"  id="user_mobile" name="user_mobile"  placeholder="Phone Number" />
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control placeHolder_text custom_input"  name="user_email" id="user_email" placeholder="Email" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <textarea class="form-control placeHolder_text custom_textArea" name="message" id="message" placeholder="Your Message"></textarea>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="myCust_checkBox">
                                                            <div class="chiller_cb">
                                                                <input id="myCheckbox3" type="checkbox" />
                                                                <label for="myCheckbox3">I am not a Robot</label>
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-md-12">
                                                    <div class="text-left">
                                                        <button onClick={sendEmail}type="button" class="btn customBtn_1">SUBMIT</button>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    </form>
					</div>
					<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">	
						<div className="callBack_right">
							<img className="img-fluid" src="assets/images/aboutus_page_img_3.jpg"/>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</section>

			

			<section className="footer_pannel_mainBox">
				<Footer />

			</section>


		</div>
	)
}

export default About