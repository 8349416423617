import React from 'react'
import Header from './header'
import Footer from './footer'


const Home = () => {
	return (
		<div>
			<header>
				<Header />
			</header>

			<section className="banner_mainBox">
				<figure className="position-relative">
					<img className="img-fluid" src="assets/images/banner_03.jpg" alt="image" />
					<figcaption>Technology,<br />IT Solutions & Services Around the World<br />
						<p>We are leading technology solutions <br/>providing company all over the world.</p>
					</figcaption>
				</figure>
			</section>


			<section className="focus_pannel_mainBox">
				<div className="container-lg container-fluid focusCount_pannel_bgColor">
					<div className="focusCount_pannel_wrapper">
						<div className="row">
							<div className="col-md-3 mb-xl-0 mb-lg-0 mb-md-0 mb-3">
								<div className="focusCount_sec">
									{/* <div className="focus_icon">
										<img src="assets/images/icon_focus_1.png" className="img-fluid" />
									</div> */}
									<div className="focus_details">
										<h2>Software Development</h2>
										<p className="asdf">Web Application Development using Java, Spring BootMobile Application Development using React Native, ReactJS,Node.jsApplication Support and Maintenance We have expertise in various apps development that will be helpful in propelling the business in online marketplace
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-3 mb-xl-0 mb-lg-0 mb-md-0 mb-3">
								<div className="focusCount_sec">
									{/* <div className="focus_icon">
										<img src="assets/images/icon_focus_2.png" className="img-fluid" />
									</div> */}
									<div className="focus_details">
										<h2>Salesforce.com Services</h2>
										<p className="asdf">	We are Salesforce partner company and provider for Sales Automation, Marketing, CPQ and Service Automation.    Consulting, implementation, Integration, Data Migration.
											Retail banking, Insurance, HR Consulting, Logistics and Remittance Service.
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-3 mb-xl-0 mb-lg-0 mb-md-0 mb-3">
								<div className="focusCount_sec">
									{/* <div className="focus_icon">
										<img src="assets/images/icon_focus_3.png" className="img-fluid" />
									</div> */}
									<div className="focus_details">
										<h2>Cloud Services</h2>
										<p className="asdf">Our cloud expert team will help your business by strategically planning all the cloud services. We are expertised in Google cloud services, Amazon cloud services and Salesforce cloud services Our Cloud services are intended to deliver easy, affordable access to applications and resources.</p>
									</div>
								</div>
							</div>
							<div className="col-md-3 mb-xl-0 mb-lg-0 mb-md-0 mb-3">
								<div className="focusCount_sec">
									{/* <div className="focus_icon">
										<img src="assets/images/icon_focus_4.png" className="img-fluid" />
									</div> */}
									<div className="focus_details">
										<h2>Digital Solutions</h2>
										<p className="asdf">We offer SEO services of the highest caliber to ensure that your brand gets higher ranking on search engines. We can create the right content for your brand using Trello, Slack, HubSpot, Canva Business with the right digital strategies to increase engagement and optimize the conversion of potential leads.</p>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>



			<section className="aboutMission_pannel_mainBox">
				<div className="container">
					<div className="aboutMission_pannel_wrapper">

						<div className="row">
							<div className="col-md-12">
								<div className="row">
									<div className="col-md-4 d-flex">
										<div className="aboutMission_sec">
											<div className="aboutMission_imgDivBox h-100">
												<img src="assets/images/mission_image_1.jpg" className="img-fluid h-100" />
											</div>
										</div>
									</div>
									<div className="col-md-8 d-flex mission_right_side">
										<div className="row">
											<div className="col-md-6 d-flex align-items-start">
												<div className="aboutMission_sec">
													<div className="aboutMission_imgDivBox">
														<img src="assets/images/mission_image_2.jpg" className="img-fluid" />
													</div>
												</div>
											</div>
											<div className="col-md-6 d-flex align-items-start">
												<div className="aboutMission_sec">
													<div className="mission_data_detail">
														<div className="mission_icon_title">
															<div className="mission_icon">
																<img src="assets/images/icon_mission_1_circlepoint.png" className="img-fluid" />
															</div>
															<div className="mission_title">Mission</div>
														</div>
														<p>Our main aim is to provide a solution that helps the enterprise in staying ahead in this changing world.</p>
													</div>
												</div>
											</div>
											<div className="col-md-6 d-flex align-items-end">
												<div className="aboutMission_sec">
													<div className="mission_data_detail eye_vission">
														<div className="mission_icon_title">
															<div className="mission_icon">
																<img src="assets/images/icon_mission_2_eye.png" className="img-fluid" />
															</div>
															<div className="mission_title">Vision</div>
														</div>
														<p>To bring transformation to the enterprise, we help them with a next- generation technological solution.</p>
													</div>
												</div>
											</div>
											<div className="col-md-6 d-flex align-items-end">
												<div className="aboutMission_sec">
													<div className="aboutMission_imgDivBox">
														<img src="assets/images/mission_image_3.jpg" className="img-fluid" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>



			<section className="whychoose_pannel_mainBox">
				<div className="container">
					<div className="whychoose_pannel_wrapper">

						<div className="row">
							<div className="col-md-12">
								<h2 className="header_title_1"><span>Reason</span> Why Choose Tech Global</h2>
								<div className="whychoose_list_pannel_box">
									<div className="row">
										<div className="col-xl-5 col-lg-5 col-md-12 col-12">
											<ul className="list-group list-group-flush customList_1">
												<li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_1.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Web Application Development</h3>
															<p>We offer clients innovative solutions to their software needs using Java, Spring Boot, AngularJS, ReactJS and JSP technologies with MongoDB, MariaDB, MySQL, MS SQL, PostgreSQL and Oracle as database
																<a href='/services' style={{ color: 'white', fontWeight: 'bold' }}>... </a> </p>
														</div>
													</div>
												</li>
												<li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_2.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Mobile Development</h3>
															<p>We are providing Hybrid Apps development using React-Native with any optional service layer (Java, Node.JS, PHP, Salesforce), Mobile payment services and Development of Financial mobile applications
																<a href='/services' style={{ color: 'white', fontWeight: 'bold' }}>... </a>
															</p>
														</div>
													</div>
												</li>
												{/* <li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_3.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Salesforce Services</h3>
															<p>we are one of the best Salesforce consulting company. Our experienced Salesforce consultants are skilled in various industries, providing solutions to solve industry challenges with faster time to market. Our Salesforce services includes Sales Cloud, Service Cloud, Marketing Cloud, Commerce Cloud and CPQ, Implementation and Support Services, Integration and Migration Services.</p>
														</div>
													</div>
												</li> */}
												<li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_7.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Web Design</h3>
															<p style={{ fontWeight: "bolder" }}>We offer best Web Design solutions for different kinds of websites using WordPress, Canva, Adobe Dreamweaver. We are designing eCommerce, Business, Entertainment, Portfolio and Educational Websites
																<a href='/services' style={{ color: 'white', fontWeight: 'bold' }}>... </a></p>
														</div>
													</div>
												</li>
												<li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_6.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Infrastructure Monitoring</h3>
															<p>We have an enthusiastic team for monitoring your entire infrastructure, application health and performance using various monitoring tools like Kafka, AWS, Azure for open and flexible integration
																<a href='/services' style={{ color: 'white', fontWeight: 'bold' }}>... </a>
															</p>
														</div>
													</div>
												</li>


											</ul>
										</div>

										<div className="col-xl-2 col-lg-2 col-md-12 col-12"></div>

										<div className="col-xl-5 col-lg-5 col-md-12 col-12">
											<ul className="list-group list-group-flush customList_1 whychoose_right">
												{/* <li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_5.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Cloud Managed Services</h3>
															<p>Our cloud expert team will help your business by strategically planning all the cloud services. We are expertised in Google cloud services, Amazon cloud services and Salesforce cloud services.</p>
														</div>
													</div>
												</li> */}
												<li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_3.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Salesforce Services</h3>
															<p>Our experienced Salesforce consultants are providing solutions to solve industry challenges using the services like Sales Cloud, Service Cloud,Marketing Cloud, Commerce Cloud and CPQ, Implementation and Support Services, Integration and Migration Services
																<a href='/services' style={{ color: 'white', fontWeight: 'bold' }}>... </a>
															</p>
														</div>
													</div>
												</li>

												<li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_4.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Digital Solutions</h3>
															<p>We can create the right content for your brand using Trello, Slack, HubSpot, Canva Business with the right digital strategies to increase engagement and optimize the conversion of potential leads
																<a href='/services' style={{ color: 'white', fontWeight: 'bold' }}>... </a> </p>
														</div>
													</div>
												</li>
												{/* <li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_7.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Web Design</h3>
															<p>We offer best web design solutions for different kinds of websites. Our experience and expertise help in creating the best designs for any website using WordPress, Canva, Adobe Dreamweaver. We are designing eCommerce, Business, Entertainment, Portfolio and Educational Websites.</p>
														</div>
													</div>
												</li> */}
												<li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_5.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Cloud Managed Services</h3>
															<p>Our cloud expert team will help your business by strategically planning all the cloud services. We are expertised in Google cloud services, Amazon cloud services and Salesforce cloud services
																<a href='/services' style={{ color: 'white', fontWeight: 'bold' }}>... </a>
															</p>
														</div>
													</div>
												</li>
												<li className="list-group-item">
													<div className="whychoose_listBox">
														<div className="whychoose_list_icon">
															<span><img src="assets/images/icon_whychoose_8.png" className="img-fluid" /></span>
														</div>
														<div className="whychoose_list_detail">
															<h3>Software Engineering</h3>
															<p>We have a dedicated team in our workplace and we are using latest technologies like Selenium, Trello, Jira for different aspects like refurbishing, updating or any other activities
																<a href='/services' style={{ color: 'white', fontWeight: 'bold' }}> ... </a>
															</p>
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>



			<section className="technology_pannel_mainBox">
				<div className="container">
					<div className="technology_pannel_wrapper">

						<div className="row">
							<div className="col-md-12">
								<h2 className="header_title_2">Technology stack used by our development team</h2>
								<div className="technology_pannel_box">
									<div className="row">
										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_aws.jpg" className="img-fluid" />
											</div>

										</div>
										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_gcp.jpg" className="img-fluid" />
											</div>

										</div>
										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_salesforce.jpg" className="img-fluid" />
											</div>
										</div>

										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_devops.jpeg" className="img-fluid" />
											</div>

										</div>

										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_java.jpeg" className="img-fluid" />
											</div>

										</div>


										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_node.jpg" className="img-fluid" />
											</div>
										</div>
										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_react.jpg" className="img-fluid" />
											</div>
										</div>

										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_reactnative.jpg" className="img-fluid" />
											</div>
										</div>
										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_mangodb.jpg" className="img-fluid" />
											</div>
										</div>
										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_postgresql.jpg" className="img-fluid" />
											</div>
										</div>

										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_hadoop.jpg" className="img-fluid" />
											</div>
										</div>
										<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
											<div className="tech_box">
												<img src="assets/images/icon_tech_elasticsearch.jpg" className="img-fluid" />
											</div>
										</div>




									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>



			{/* <section className="event_pannel_mainBox">
				<div className="container">
					<div className="event_pannel_wrapper">

						<div className="row">
							<div className="col-md-12">
								<h2 className="header_title_2 mb-3">Events</h2>
								<div className="text_1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non tortor iaculis, fringilla ex et, laoreet elit.</div>
								<div className="event_pannel_box">
									<div className="row">
										<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
											<div className="event_card_box">
												<img src="assets/images/event_img_1.jpg" className="img-fluid card_img" />
												<p className="text_2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non .</p>
												<p className="text_3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non tortor iaculis, fringilla ex et, laoreet elit.</p>
												<a href="javascript:;" className="readMore">Read More <i className="fa fa-arrow-right"></i></a>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
											<div className="event_card_box">
												<img src="assets/images/event_img_2.jpg" className="img-fluid card_img" />
												<p className="text_2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non .</p>
												<p className="text_3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non tortor iaculis, fringilla ex et, laoreet elit.</p>
												<a href="javascript:;" className="readMore">Read More <i className="fa fa-arrow-right"></i></a>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
											<div className="event_card_box">
												<img src="assets/images/event_img_3.jpg" className="img-fluid card_img" />
												<p className="text_2">We are leading technology solutions proving company all over the world.</p>
						
												<a href="javascript:;" className="readMore">Read More <i className="fa fa-arrow-right"></i></a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section> */}



			<section className="contact_pannel_mainBox">
				<div className="container-fluid">
					<div className="contact_pannel_wrapper">

						<div className="row d-flex align-items-center">
							<div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 px-0">
								<div className="contact_map_box">

									<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15720.487885243036!2d78.148557!3d9.9237987!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x35b90045f23c3fbf!2sTech%20Global%20India!5e0!3m2!1sen!2sin!4v1673206384306!5m2!1sen!2sin" width="100%" height="500" border='0'></iframe>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12">
								<div className="contact_detail_box">
									<div className="contact_title_1">CONTACT</div>
									<div className="contact_address">
										<h1 className="contact_title_2">Get In Touch</h1>
										<p>
											#62, 2nd Floor, HIG Colony,
											Annanagar 80 Feet Road,
											Madurai-625020, Tamil Nadu, India
										</p>
										<ul className="list-group list-group-flush customList_2_contact">
											<li className="list-group-item">
												<span><a href="tel: 0452 - 7963403">0452-7963403</a></span>
											</li>
											<li className="list-group-item">
												<span><a href="mailto:hr@techglobalindia.com">hr@techglobalindia.com</a></span>
											</li>
											<li className="list-group-item">
												<span>10:00AM - 07:00PM</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>



			<Footer />



		</div>
	)
}

export default Home