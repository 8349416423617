import React ,{useRef}from 'react'
import Header from './header'
import Footer from './footer'
import emailjs from '@emailjs/browser';

const Services = () => {
    const form = useRef();
    const sendEmail = (e) => {
        console.log("reach send email", e)
        e.preventDefault();
        emailjs.sendForm("service_6e4pg09", "template_nbsw0yj", form.current, 'vpNHCXG0q-u8e5EwB') // hr
            .then((result) => {
                console.log("result", result.text);
            }, (error) => {
                console.log("error", error.text);
            });

    }
    return (
        <div>
            <header>
                <Header />
            </header>

            <section className="service_banner_pannel_mainBox">
                <div className="service_banner_pannel_box">
                <figure className="position-relative">
					<img className="img-fluid" src="assets/images/banner_03.jpg" alt="image"/>
             <figcaption> 
                     <h3 style={{color:'white'}}> We provide services that adapt<br/> perfectly to your project requirements and business desires.
				</h3>  </figcaption>
          
				</figure>
            </div>
            </section>

            <section className="feature_service_pannel_mainBox">
                <div className="container">
                    <div className="feature_service_pannel_wrapper">

                        <div className="feature_service_pannel_box">
                            <div className="contact_title_1 mb-3">SERVICES</div>
                            <h5 className="halfLine_title_1">Our Featured Services</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="feature_service_box fromLeft">
                                        <div className="row">
                                            <div className="col-md-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 p-4 mb-3">
                                                <img className="img-fluid" src="assets/images/webdev.jpg" />
                                            </div>

                                            <div className="col-md-8 service_detail">
                                                <h3 className="service_title">Web Application Development</h3>
                                                <p>Software Application Development and Maintenance is a part of TGI's Core activity.We offer clients innovative solutions to their software needs using Java, Spring Boot, AngularJS, ReactJS and JSP technologies with MongoDB, MariaDB, MySQL, MS SQL and Oracle as database. We provide end-to-end business solution to address the support and software maintenance services. Our company has proven experience in delivering quality offshore software application support to business solutions that run the busine.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature_service_box fromRight">
                                        <div className="row">
                                            <div className="col-md-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                                                <img className="img-fluid" src="assets/images/mobile.jpg" />
                                            </div>
                                            <div className="col-md-8 service_detail">
                                                <h3 className="service_title " style={{textAlign:'left'}}>Mobile Development</h3>
                                                <p style={{textAlign:'left'}}>We have an experienced and trained Mobile Development team that can help to convert your idea into a protected and cross-functional mobile application.Our mobile app service includes Integrating mobile solutions into your enterprise systems, Developing Apps using hybrid technologies, Expertise in Hybrid Apps development using React-Native with any optional service layer (Java, Node.Js, PHP, Salesforce), Mobile payment services and Development of financial mobile applications</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature_service_box fromLeft">
                                        <div className="row">
                                            <div className="col-md-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                                                <img className="img-fluid" src="assets/images/salesforce2.jpg" />
                                            </div>
                                            <div className="col-md-8 service_detail">
                                                <h3 className="service_title">Salesforce Services</h3>
                                                <p>We have got the best team to aid your CRM journey. Be it technical or business skills, short or long-term duration, integrations or optimizations, we as one of the Salesforce consulting company, will always be there to help you with our Salesforce team. Our experienced Salesforce consultants are skilled in various industries, providing solutions to solve industry challenges with faster time to market.Our salesforce services includes Sales Cloud, Service Cloud, Marketing Cloud, Commerce Cloud and CPQ, Implementation and Support Services, Integration and Migration Services</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature_service_box fromRight">
                                        <div className="row">
                                            <div className="col-md-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 p-4 mb-3">
                                                <img className="img-fluid" src="assets/images/digital.jpg" />
                                            </div>
                                            <div className="col-md-8 service_detail">
                                                <h3 className="service_title" style={{textAlign:'left'}}>Digital Solutions</h3>
                                                <p style={{textAlign:'left'}}>We offer SEO services of the highest caliber to ensure that your brand gets higher ranking on search engines. We can create the right content for your brand and tie it up with the right digital strategies to increase engagement and optimize the conversion of potential leads. We can curate custom social media marketing campaigns for your brand so that your social media handles can become an integral part of your arsenal. From increasing traffic and conversions, to getting better leads, TGI digital advertising and Ad management services can help you reach your goal.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature_service_box fromLeft">
                                        <div className="row">
                                            <div className="col-md-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                                                <img className="img-fluid" src="assets/images/cloud.jpg" />
                                            </div>
                                            <div className="col-md-8 service_detail">
                                                <h3 className="service_title">Cloud Managed Services</h3>
                                                <p>In order to be competitive, companies in today's time have to expand and organize. Cloud can be said complicated but at the same time authoritative. Our cloud expert team will help your business by strategically planning all the cloud services. We are expertised in Google cloud services, Amazon cloud services and Salesforce cloud services.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature_service_box fromRight">
                                        <div className="row">
                                            <div className="col-md-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                                                <img className="img-fluid" src="assets/images/infra.jpg" />
                                            </div>
                                            <div className="col-md-8 service_detail">
                                                <h3 className="service_title" style={{textAlign:'left'}}>Infrastructure Monitoring</h3>
                                                <p style={{textAlign:'left'}}>We have an enthusiastic team for infrastructure monitoring, that will help you monitor all your IT infrastructure and will also provide you analytical insights for the same. This will tell you how your business is performing, capacity & much more. We use various monitoring tools to monitor hardware, software, applications health and application data reporting.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature_service_box fromLeft">
                                        <div className="row">
                                            <div className="col-md-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                                                <img className="img-fluid" src="assets/images/webdesign.jpg" />
                                            </div>
                                            <div className="col-md-8 service_detail">
                                                <h3 className="service_title">Web Design</h3>
                                                <p>TGI's offer some of the best web design solutions for different kinds of websites. Our experience and expertise helps in creating the best designs for any website. We have already formed a huge base of clients across the world with our effective and efficient services. The top notch design solutions that we have offered our clients till date have helped us achieve success.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature_service_box fromRight">
                                        <div className="row">
                                            <div className="col-md-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                                                <img className="img-fluid" src="assets/images/software1.jpg" />
                                            </div>
                                            <div className="col-md-8 service_detail">
                                                <h3 className="service_title" style={{textAlign:'left'}}>Software Engineering</h3>
                                                <p style={{textAlign:'left'}}>As the technologies and industries are changing quickly, all you need to do is keep your applications updated. We have a dedicated team in our workplace, who always keep an eye on the latest application changes as per today’s requirements. From different aspects like refurbishing, updating, or any other activities we will always support your business in software reengineering.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            <section className="workProcess_pannel_mainBox">
                <div className="container">
                    <div className="workProcess_pannel_wrapper">

                        <div className="workProcess_pannel_box">
                            <div className="row">
                                <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <h5 className="halfLine_title_1">Our Working Process - How We Work For Our Customers</h5>
                                </div>
                                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <button type="button" className="btn customBtn_1 btn_contactus">CONTACT US</button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="workProcess_dataBox">
                                        <div className="accordion" id="parentServiceWork">
                                            <div className="card">
                                                <div className="card-header">
                                                    <a href="#" className="btn_header_link" data-toggle="collapse" data-target="#serviceWork1" aria-expanded="true">1. Discovery</a>
                                                </div>
                                                <div id="serviceWork1" className="collapse show" data-parent="#parentServiceWork">
                                                    <div className="card-body">
                                                        Developing the initial product idea into a clear understanding of its business goals, functional goals and the effort behind implementing this solution.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header">
                                                    <a href="#" className="btn_header_link collapsed" data-toggle="collapse" data-target="#serviceWork2" aria-expanded="true">2. Planning</a>
                                                </div>
                                                <div id="serviceWork2" className="collapse" aria-labelledby="faqhead2" data-parent="#parentServiceWork">
                                                    <div className="card-body">
                                                        Heart of the project life cycle, and tells everyone involved where you're going and how you're going to get there. Set goals using S.M.A.R.T Method Specific, Measurable, Attainable, Realistic & Timely.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header">
                                                    <a href="#" className="btn_header_link collapsed" data-toggle="collapse" data-target="#serviceWork3" aria-expanded="true">3. Execute</a>
                                                </div>
                                                <div id="serviceWork3" className="collapse" aria-labelledby="faqhead3" data-parent="#parentServiceWork">
                                                    <div className="card-body">
                                                        Bringing several individuals together in order to efficiently, achieve goals outline in planning, Engender internal motivation in your team and effectively complete a project. We provide to regular status and reports to client.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header">
                                                    <a href="#" className="btn_header_link collapsed" data-toggle="collapse" data-target="#serviceWork4" aria-expanded="true">4. Deliver</a>
                                                </div>
                                                <div id="serviceWork4" className="collapse" aria-labelledby="faqhead3" data-parent="#parentServiceWork">
                                                    <div className="card-body">
                                                        We have better management for shorten time-to-market, higher quality and cost-effectiveness, Delivery process setup, ongoing communication, Transparent delivery process, Quality control and time control, expectation management, improve management, change request, new features.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section className="callBack_pannel_mainBox">
                <div className="container">
                    <div className="callBack_pannel_wrapper">

                        <div className="callBack_pannel_box">
                            <div className="row">
                                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-3">
                                    <h5 className="halfLine_title_2">Request For Call Back</h5>
                                    <form id="form" ref={form} onSubmit={sendEmail}>
                                        <div class="callBack_left">

                                            <div class="callBack_form_sec">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control placeHolder_text custom_input" name="user_name" id="user_name" placeholder="Name" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control placeHolder_text custom_input" id="user_mobile" name="user_mobile" placeholder="Phone Number" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control placeHolder_text custom_input" name="user_email" id="user_email" placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <textarea class="form-control placeHolder_text custom_textArea" name="message" id="message" placeholder="Your Message"></textarea>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="myCust_checkBox">
                                                                <div class="chiller_cb">
                                                                    <input id="myCheckbox3" type="checkbox" />
                                                                    <label for="myCheckbox3">I am not a Robot</label>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="text-left">
                                                            <button onClick={sendEmail} type="button" class="btn customBtn_1">SUBMIT</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <div className="callBack_right">
                                        <img className="img-fluid" src="assets/images/aboutus_page_img_3.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="footer_pannel_mainBox">
                <Footer />
            </section>







        </div>
    )
}

export default Services