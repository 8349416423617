import React, { useRef, useState } from 'react'
import Header from './header';
import Footer from './footer';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        console.log("reach send email", e)
        e.preventDefault();
        emailjs.sendForm("service_6yevyg3","template_chs463s", form.current, 'JmsCD6aSls3RIK_mP') // hr
            .then((result) => {
                console.log("result", result.text);
            }, (error) => {
                console.log("error", error.text);
            });

    }
    return (
        <div>
            <Header />

            <section className="banner_mainBox">
				<figure className="position-relative">
					<img className="img-fluid" src="assets/images/banner_03.jpg" alt="image" />
					<figcaption>Technology,<br/>IT Solutions & Services Around the World<br/>
						<p>We are leading technology solutions providing company all over the world.</p>
					</figcaption>
				</figure>
			</section>


            <section class="callBack_pannel_mainBox">
                <div class="container">
                    <div class="callBack_pannel_wrapper">

                        <div class="callBack_pannel_box">
                            <div class="row">
                                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-3">
                                    <h5 class="halfLine_title_2">Request For Call Back</h5>
                                    <form id="form" ref={form} onSubmit={sendEmail}>
                                        <div class="callBack_left">

                                            <div class="callBack_form_sec">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control placeHolder_text custom_input" name="user_name" id="user_name" placeholder="Name" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control placeHolder_text custom_input" id="user_mobile" name="user_mobile" placeholder="Phone Number" />
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control placeHolder_text custom_input" name="user_email" id="user_email" placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <textarea class="form-control placeHolder_text custom_textArea" name="message" id="message" placeholder="Your Message"></textarea>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="myCust_checkBox">
                                                                <div class="chiller_cb">
                                                                    <input id="myCheckbox3" type="checkbox" />
                                                                    <label for="myCheckbox3">I am not a Robot</label>
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="text-left">
                                                            <button onClick={sendEmail} type="button" class="btn customBtn_1">SUBMIT</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <div class="callBack_right">
                                        <img class="img-fluid" src="assets/images/aboutus_page_img_3.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="contact_pannel_mainBox">
                <div className="container-fluid">
                    <div className="contact_pannel_wrapper">

                        <div className="row d-flex align-items-center">
                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 px-0">
                                <div className="contact_map_box">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15720.487885243036!2d78.148557!3d9.9237987!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x35b90045f23c3fbf!2sTech%20Global%20India!5e0!3m2!1sen!2sin!4v1673206384306!5m2!1sen!2sin" width="100%" height="500"></iframe>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12">
                                <div className="contact_detail_box">
                                    <div className="contact_title_1">CONTACT</div>
                                    <div className="contact_address">
                                        <h1 className="contact_title_2">Get In Touch</h1>
                                        <p>
                                            #62, 2nd Floor, HIG Colony,
                                            Annanagar 80 Feet Road,
                                            Madurai-625020, Tamil Nadu, India
                                        </p>
                                        <ul className="list-group list-group-flush customList_2_contact">
                                            <li className="list-group-item">
                                                <span><a href="tel: 0452 - 7963403">0452-7963403</a></span>
                                            </li>
                                            <li className="list-group-item">
                                                <span><a href="mailto:hr@techglobalindia.com">hr@techglobalindia.com</a></span>
                                            </li>
                                            <li className="list-group-item">
                                                <span>10:00AM - 07:00PM</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>





            <section class="footer_pannel_mainBox">
                <Footer />
            </section>











        </div>
    )
}

export default Contact;